html {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  background-color: #051626;
  margin: 10px;
}

.card {
  background-color: #F8F6F4;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  border-radius: 10px;
  margin: 20px;
  padding: 15px;
  min-width: 300px;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.inst {
  position: relative;
  top: 25px;
  text-align: center;
  margin-top: auto;
  margin-bottom: 5%;
  display: flex; 
  flex-direction: column; 
  align-items: center;
  text-align: center;
}


.about-me{
  color: #F8F6F4;
}

h1 {
  text-align: center;
}

h1,
h2 {
  margin: 20px;
  padding: 10px;
}

p {
  overflow-wrap: normal;
  margin: 10px;
  text-align: center;
}

.section {
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
}

.card img {
  height: 175px;
  width: 175px;
  margin-top: 20px;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.institution {
  margin-bottom: 5px;
  margin-top: 0%;
  /* position: relative; */
  /* font-size: 1rem; */
  background-color: #333;
  color: white;
  font-weight: bold;
  font-size: 1.1rem;
  text-decoration: none;
  padding: 10px 20px;
  /* margin: 10px; */
  border-radius: 10px;
  min-width: 120px;
}

.dates {
  font-size: 1rem;
  margin-bottom: 5%;
  margin-top: 0%;
  background-color: #ffcb30;
  border-radius: 10px;
  width: fit-content;
  padding: 5px 10px;
  /* margin: 5px; */
  /* display: block; */
  display: flex;
  justify-content: center;
  font-weight: bold;
  text-align: center;
}

.highlight {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 20px;
}

.subheader {
  margin: 20px;
  font-size: 1rem;
  font-weight: bold;
}

.subtext {
  font-size: 1.2rem;
  margin: 0 10px 10px 10px;
  overflow-wrap: normal;
  text-align: center;
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
  justify-content: center;
  margin: 10px;
}

.tag {
  background-color: #ffcb30;
  border-radius: 10px;
  width: fit-content;
  padding: 5px 10px;
  margin: 5px;
  display: block;
  font-weight: bold;
}

.link-section {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.link-section a {
  background-color: #333;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  text-decoration: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 10px;
  min-width: 120px;
}

.link-section a:hover {
  background-color: rgb(100, 100, 100);
}

.provider {
  font-size: 1.2rem;
}

.navbar {
  background-color: #F8F6F4;
  border-radius: 10px;
  padding: 10px;
  margin: 20px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.name {
  margin: 0 10px;
  text-decoration: none;
  padding: 10px;
  font-size: 2rem;
  font-weight: bold;
  color: black;
}

.navbar-menu * {
  padding: 10px;
  margin: 10px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  color: black;
}

.navbar-menu *:hover:not(.selected) {
  background-color: #ffcb30;
  border-radius: 10px;
  font-weight: bold;
  color: black;
}

.selected {
  background-color: #ffcb30;
  border-radius: 10px;
  font-weight: bold;
  color: black;
}

.section-header,
.section-subheader {
  color: #F8F6F4;
  text-align: center;
}

/* Responsive Navbar */

@media screen and (min-width: 1400px) {
  .navbar-toggle {
    display: none;
  }

  .navbar-menu {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1399px) {
  .card {
    min-width: 300px;
    margin: 10px;
  }

  .navbar {
    margin: 20px 10px 10px;
  }

  .navbar-toggle {
    cursor: pointer;
    border: none;
    background-color: transparent;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .navbar-menu {
    opacity: 0;
    height: 0;
    width: 0;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
  }

  .navbar.opened .navbar-menu {
    background-color: #F8F6F4;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    opacity: 1;
    margin: 20px 30px 10px;
    height: fit-content;
    width: 100%;
  }

  .navbar-menu * {
    width: 100%;
  }

  .icon-bar {
    display: block;
    width: 25px;
    height: 4px;
    margin: 2px;
    transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out,
      opacity 0.2s ease-in-out;
    background-color: black;
  }

  .navbar.opened .navbar-toggle .icon-bar:first-child,
  .navbar.opened .navbar-toggle .icon-bar:last-child {
    position: absolute;
    margin: 0;
    width: 30px;
  }

  .navbar.opened .navbar-toggle .icon-bar:first-child {
    transform: rotate(45deg);
  }

  .navbar.opened .navbar-toggle .icon-bar:nth-child(2) {
    opacity: 0;
  }

  .navbar.opened .navbar-toggle .icon-bar:last-child {
    transform: rotate(-45deg);
  }
}

@media screen and (max-width: 380px) {
  .card {
    min-width: 250px;
  }
}

.navbar-toggle:hover .icon-bar {
  background-color: #ffcb30;
}

.contact-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.link {
  margin: 20px;
  padding: 10px;
  text-align: center;
  color: #333;
  border-radius: 10px;
}

a.link {
  cursor: pointer;
  text-decoration: none;
}

.link:hover {
  background-color: #333;
  color: #F8F6F4;
}

.icon {
  margin-top: 10px;
}

img.profile-img {
  border-radius: 50%;
  height: 150px;
  width: 150px;
  margin: 20px;
}

.description {
  font-size: 1.2rem;
}

.description-section {
  margin: 20px;
}

@media screen and (max-width: 360px) {
  .name {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 320px) {
  .name {
    font-size: 1.2rem;
  }
}
